import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Top-left">
          <p>Under construction</p>
          <p>Temple of Promise site coming soon...</p>
        </div>
      </header>
    </div>
  );
}

export default App;
